.download-btn * {
    background: dodgerblue;
    max-width: 10rem;
    padding: 1.2rem 2rem;
    text-decoration: none;
    color: white;
    font-weight: bolder;
    font-size: 1.5rem;
    display: block;
    border-radius: 20px;
    width: fit-content;
}

.app-name {
    --primary-clr: #3f4b5c;
    font-size: 2.5rem;
    color: var(--primary-clr);
    margin: 0;
}

.details-text {
    margin: 0;
    font-weight: lighter;
    font-size: 1.7rem;
    padding-inline: 1em;
    text-align: center;
}

.download-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.download-buttons {
    display: flex;
    gap: 1em;
}

.home-container {
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.available-txt {
    font-size: 0.8rem;
    margin: 0.4rem;
    color: dimgray;
}

.logo-image {
    height: 10rem;
    aspect-ratio: 1;
    box-shadow: 2px 3px 10px -2px rgba(0,0,0,0.5);
    -webkit-box-shadow: 2px 3px 10px -2px rgba(0,0,0,0.5);
    -moz-box-shadow: 2px 3px 10px -2px rgba(0,0,0,0.5);
    border-radius: 35px;
    margin-top: 50px;
}

.app-image img {
    max-width: 15em;
}

.app-image {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bn46 {
    width: 150px;
    height: 50px;
    cursor: pointer;
}

.bn45 {
    width: 150px;
    height: 50px;
    cursor: pointer;
}

.disabled-btn {
    filter: brightness(60%);
    pointer-events: none;
    cursor: default;
}

/*QUESTION5 - Flexbox*/
.app-image-container {
    background: #f2f2f6;
    width: 100%;
    border-radius: 50px;
    padding: 3em;
    display: flex;
    justify-content: space-evenly;
    box-sizing: border-box;
}

/*QUESTION5 - Media queries*/
@media only screen and (max-width: 970px) {
    .app-image-container {
        flex-direction: column;
        width: 80%;
        padding: 1em 1em 60px;
        gap: 40px;
    }
}