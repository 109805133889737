.faq-item {
    background: #f2f2f6;
    width: 100%;
    border-radius: 25px;
    padding: 1.8em 3em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-sizing: border-box;
    gap: 0.8em;
}

.faq-item p {
    margin: 0;
}

.faq-item h3 {
    margin: 0;
}

@media only screen and (max-width: 600px) {
    .faq-item {
        padding: 1.8em 1.8em;
    }
}