.nav-items {
    display: flex;
    list-style: none;
    margin: 0;
    align-items: center;
    gap: 1rem;
    color: gray;
}

.nav-items li * {
    cursor: pointer;
    transition: color 200ms ease;
    text-decoration: none;
    color: gray;
}

.nav-items li *:hover {
    color: dodgerblue;
}

header div h2 * {
    transition: color 200ms ease;
    cursor: pointer;
    text-decoration: none;
    width: auto;
    height: 2rem;
}

header {
    display: flex;
    padding-inline: 2rem;
    border-bottom: lightgray 1px solid;
    position: relative;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
}
.nav-body {
    background: white;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.nav-toggle-label {
    z-index: 10;
}

.nav-toggle {
    display: none;
}

.nav-toggle-label {
    display: none;
}

.link-selected {
    color: dodgerblue !important;
}

@media only screen and (max-width: 600px) {
    header div h2 * {
        transition: color 200ms ease;
        cursor: pointer;
        text-decoration: none;
        width: auto;
        height: 1.5rem;
    }
    
    nav {
        background: white;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        border-bottom: lightgrey 1px solid;
        padding: 20px;
        font-size: 1.3rem;
        box-sizing: border-box;
        z-index: 1;
        transition: top 500ms ease-in-out;
    }
    
    header {
        border-bottom: none;
    }
    
    .nav-items {
        flex-direction: column;
    }
    
    .nav-toggle-label {
        display: block;
        position: relative;
        background: white;
        height: 1.2em;
        width: 1.6em;
    }
    
    .nav-toggle-label span {
        --transition: 250ms ease; 
        display: block;
        background: black;
        height: 3px;
        width: 1.5em;
        position: absolute;
        transition: 
                transform var(--transition), 
                opacity var(--transition), 
                left var(--transition),
                top var(--transition);
    }
    
    .nav-toggle-label span:nth-of-type(1) {
        top: 8px;
        transform: rotate(45deg);
    }

    .nav-toggle-label span:nth-of-type(2) {
        top: 8px;
        transform: rotate(-45deg);
    }

    .nav-toggle-label span:nth-of-type(3) {
        opacity: 0;
        top: 8px;
        left: 10px;
    }
    
    /*Closed*/
    .nav-toggle:checked ~ .nav-toggle-label span:nth-of-type(1) {
        top: 16px;
        transform: rotate(0deg);
    }

    .nav-toggle:checked ~ .nav-toggle-label span:nth-of-type(2) {
        top: 0;
        transform: rotate(0deg);
    }

    .nav-toggle:checked ~ .nav-toggle-label span:nth-of-type(3) {
        top: 8px;
        left: 0;
        opacity: 1;
    }
    
    .nav-toggle:checked ~ nav {
        top: -112%;
    }
}